<template>    
    <div class="card card-default">
        <div class="card-header">
            <h5>Pagos TPV</h5>
        </div>
        <!-- Contenido específico para la pestaña Opciones -->
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>                                
                                Nombre
                            </th>
                            <th style="display: flex; justify-content: space-around;">
                                <Calendar style="height: 30px; width: 150px;" dateFormat="dd/mm/yy" v-model="fecha_inicio" showIcon iconDisplay="input">
                                    <template #inputicon="{ clickCallback }">
                                        <InputIcon class="pi pi-clock cursor-pointer" @click="clickCallback" />
                                    </template>
                                </Calendar> 
                                <Calendar style="height: 30px; width: 150px;" dateFormat="dd/mm/yy" v-model="fecha_fin" showIcon iconDisplay="input">
                                    <template #inputicon="{ clickCallback }">
                                        <InputIcon class="pi pi-clock cursor-pointer" @click="clickCallback" />
                                    </template>
                                </Calendar>                         
                            </th>
                            <th>
                                <input v-model="filtro" type="text">
                            </th>
                            <th>
                                <strong>Estado</strong>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="pago in pagosFiltrados" :key="pago.id_pago_tpv">
                            <td>                            
                                <p>{{ pago.nombre }}</p>
                            </td>
                            <td style="text-align: center;">
                                {{ pago.fecha }}
                            </td>
                            <td>
                                {{ pago.expediente }}
                            </td>
                            <td>
                                <strong>{{ pago.estado }}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>      
            </div>
        </div>
    </div>
</template>

<script>
import Calendar from 'primevue/calendar';
import { PwgsApi } from '../../../services/PwgsApi';

export default {
    data() {
        return {
            fecha_inicio: '',
            fecha_fin: '',
            pagos: [],
            filtro: '',
        };
    },
    components: {
        Calendar,
    },
    computed: {
        pagosFiltrados() {
            if (this.filtro) {
                return this.pagos.filter(pago =>
                    pago.expediente.toLowerCase().includes(this.filtro.toLowerCase())
                );
            }
            return this.pagos;
        },
    },
    methods: {
        async obtenerPagos() {
            const api = new PwgsApi();
            const hoy = new Date();
            const fechaEnMilisegundos = hoy.getTime();
            const restar = 30 * 24 * 60 * 60000;
            const fechaNueva = new Date(fechaEnMilisegundos - restar);

            this.fecha_inicio = fechaNueva.toLocaleDateString();
            this.fecha_fin = hoy.toLocaleDateString();

            const body = { "fecha_inicio": fechaNueva.toLocaleDateString(), "fecha_fin": hoy.toLocaleDateString() };
            this.pagos = await api.post('ste/pagos-tpv', body);
        },
        async obtenerNuevosPagos(){
            try{
                const api = new PwgsApi();

                try{
                    this.fecha_inicio = this.fecha_inicio.toLocaleDateString();
                }catch(e){
                console.log(e);
                }

                try{
                    this.fecha_fin = this.fecha_fin.toLocaleDateString();
                }catch(e){
                console.log(e);
                }
                

                var body = {"fecha_inicio": this.fecha_inicio, "fecha_fin":this.fecha_fin};


                this.pagos = await api.post('ste/pagos-tpv', body);                

            }catch(e) {
                alert(e);
            }
        },
    },
    watch: {
        fecha_inicio() {
            this.obtenerNuevosPagos();
        },
        fecha_fin() {
            this.obtenerNuevosPagos();
        },
    },
    mounted() {
        this.obtenerPagos();
    },
};
</script>

